<template>
    <el-card class="box-card-b" shadow="never">
        <el-upload class="upload-demo logo" drag action="" multiple :on-change="handleChange" :auto-upload="false"
            :show-file-list="false">
            <el-icon class="el-icon--upload ">
                <upload-filled />
            </el-icon>
            <view class="el-upload__text logo"> 拖拽文件到此处 <em>上传</em> </view>
        </el-upload>
        <el-card class="box-card-t" shadow="never" v-loading="loading">
            <template #header>
                <div class="card-header" @click="inputBlur">
                    <span>
                        <el-select style="width: 90px;" v-model="data.tabname" placeholder="Select" size="small"
                            @change='getheaders()'>
                            <el-option v-for="item in tabsName" :key="item" :label="item" :value="item" />
                        </el-select>
                    </span>
                    <span>
                        <el-button size="small" type="primary" @click="emptyAll()" :disabled='has'>清空
                        </el-button>
                        <el-button size="small" type="primary" @click="ExporttAPI('#table-data2')">导出表
                        </el-button>
                        <el-button size="small" type="primary" @click="updatas()" :disabled='has'>上传
                        </el-button>
                    </span>
                </div>
            </template>
            <el-table :data="fileList" :height="innerHeight <= 400 ? 350 : innerHeight - 380" stripe size="small"
                id="table-data2">
                <el-table-column v-for="(item, index) in tableHead" :key="index" :label="item.label"
                    :prop="item.property" show-overflow-tooltip>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog v-model="warning" :title="dialogTitle" width="70%" :before-close="handleClose">
            <el-table :data="arrList" max-height="500" show-overflow-tooltip="true" stripe size="small" id="table-data"
                :row-class-name="tableRowClassName" @cell-click="tabClick" v-loading="loading">
                <el-table-column v-for="(item, index) in tableHead" :key="index" :label="item.label"
                    :prop="item.property">
                    <template #default="scope">
                        <span
                            v-if="scope.row.index === tabClickIndex && tabClickLabel === item.label&&item.type === 'date-picker'">
                            <el-date-picker v-model="scope.row[item.property]" type="date" placeholder="Pick a day"
                                size="small" @blur="inputBlur" format="YYYY-MM-DD" value-format="YYYY-MM-DD"
                                range-separator="-" />
                        </span>
                        <span
                            v-else-if="scope.row.index === tabClickIndex && tabClickLabel === item.label&&item.type === 'select'">
                            <el-select v-model="scope.row[item.property]" placeholder="Select" size="small">
                                <el-option v-for="i in item.option" :key="i" :label="i" :value="i" />
                            </el-select>
                        </span>
                        <span
                            v-else-if="scope.row.index === tabClickIndex && tabClickLabel === item.label&&item.type === 'input'">
                            <el-input v-model="scope.row[item.property]" placeholder="手机编号" size="small"
                                @blur="inputBlur" />
                        </span>
                        <span
                            v-else-if="scope.row.index === tabClickIndex && tabClickLabel === item.label&&item.type === 'number'">
                            <el-input v-model="scope.row[item.property]" placeholder="Please input" @blur="inputBlur"
                                size="small" type="number" />
                        </span>
                        <el-tooltip
                            v-else-if=" errorMessage[scope.row.index][item.property] != scope.row[item.property]"
                            class=" box-item" effect="dark" :content="errorMessage[scope.row.index][item.property]"
                            placement="top-start">
                            <span
                                :style="errorMessage[scope.row.index][item.property] == scope.row[item.property]?'color: black':'color: red'">{{ scope.row[item.property]
                            }}</span>
                        </el-tooltip>
                        <span v-else>{{ scope.row[item.property]}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="giveUp()">取消</el-button>
                    <el-button type="danger" @click="warning = false">
                        删除
                    </el-button>
                    <el-button type="primary" @click="inspect()" v-if="!dialogPrimaryButton">
                        更改
                    </el-button>
                    <el-button type="primary" @click="correctUpdate()" v-if="dialogPrimaryButton">
                        更新
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </el-card>
</template>

<script>
    // 数据上传页面
    // @ is an alias to /src
    //导出文件
    import jsonToExcel from '@/utils/jsonToExcel'
    import axios from "@/utils/request";
    //导入文件
    import {
        importExcel,
        booleanJudge
    } from '@/utils/importExcel'
    import {
        ElMessage,
        ElLoading,
    } from 'element-plus'
    import {
        getabs
    } from '@/utils/tools'
    import {
        mapState
    } from 'vuex'
    export default {
        name: 'Upload',
        computed: {
            // Vue计算属性
            ...mapState(['innerHeight']),
        },
        created() {
            axios({
                method: "post",
                url: 'getheader',
                data: {
                    tabname: "root",
                },
            }).then(res => {
                this.tabsName = res
                this.data.tabname = res[0]
                this.getheaders()
            })
        },
        data() {
            return {
                dialogTitle: '您有数据需要修改',
                dialogPrimaryButton: false, //更改按钮
                disabled: false, //控制解析按钮是否显示
                has: true, //控制清除，导出，上传，按钮是否可用
                tabsName: [],
                warning: false,
                multiple: true,
                fileList: [], //表数据
                arrList: [], //错误表
                errorMessage: [], //错误信息
                loading: false,
                tabClickIndex: null, // 点击的单元格
                tabClickLabel: '', // 当前点击的列名
                data: {
                    tabname: "客户列表",
                    data: [],
                    modifyData: false, //是否修改
                },
                tableHead: [], //表头
            }
        },
        methods: {
            getheaders() {
                this.loading = true
                axios({
                    method: "post",
                    url: 'getheader',
                    data: this.data,
                }).then(res => {
                    this.loading = false
                    this.tableHead = res.tableHead
                    this.arrList = []
                    this.fileList = []
                    this.errorMessage = []
                })
            },
            updatas() {
                this.loading = true
                this.data.data = this.fileList
                axios({
                    method: "post",
                    url: 'updata',
                    data: this.data,
                }).then(res => {
                    this.loading = false
                    if (res.hasOwnProperty("add")) {
                        ElMessage({
                            type: "success",
                            message: `成功添加${res.add}行数据，成功更新${res.update}行数据`,
                        })
                        this.data.modifyData = false
                    } else {
                        this.fileList = res.fileList
                        this.errorMessage = res.errorMessage
                        this.arrList = res.error
                        console.log(res.length)
                        if (res.hasOwnProperty("reviseerrordata")) { //如果错误对象长度大于0 弹出错误修改窗
                            this.dialogTitle = '这些数据您曾将上传过，您要重新上传，更新这些数据嘛？'
                            this.dialogPrimaryButton = true
                        } else { //如果错误对象长度大于0 弹出错误修改窗
                            this.dialogTitle = '这些数据有问题'
                        }
                        this.warning = true
                    }
                })
            },
            correctUpdate() {
                this.arrList.forEach(v => {
                    this.fileList.push(v);
                })
                this.arrList.length = 0
                this.errorMessage.length = 0
                this.data.modifyData = true
                this.warning = false
            },
            handleClose(v) {
                this.warning = false
                this.updata = false
            },
            emptyAll() {
                this.fileList.length = 0
                this.has = true
            },
            inspect() { //错误探窗修正
                this.inputBlur()
                this.loading = true
                let tH = {
                    name: this.data.tabname,
                    tableHead: [...this.tableHead]
                }
                let arrLists = [...this.arrList]
                this.arrList.length = 0
                this.errorMessage.length = 0
                arrLists.forEach(v => {
                    booleanJudge(v, tH).then(b => {
                        if (b.boolean === 'correct') {
                            this.fileList.push(b.correct);
                        }
                        if (b.boolean === 'error') {
                            this.arrList.push(b.error);
                            this.errorMessage.push(b.errorMessage);
                        }
                        if (this.arrList.length == 0) { //如果错误对象长度等于0 关闭错误修改窗
                            this.warning = false
                        }
                    })
                })
                this.loading = false
            },
            tableRowClassName({ // 把每一行的索引放进row
                row,
                rowIndex
            }) {
                row.index = rowIndex
            },
            tabClick(row, column) { // 添加明细原因 row 当前行 column 当前列
                this.tabClickIndex = row.index
                this.tabClickLabel = column.label
            },
            inputBlur() { // 失去焦点初始化
                this.tabClickIndex = null
                this.tabClickLabel = ''
            },
            giveUp() { //放弃上传数据
                this.fileList.length = 0
                this.warning = false
                this.has = true
            },
            handleChange(file) { // 读入Excel文件内容： 核心部分代码(handleChange 和 importfile)
                ElMessage({
                    type: "warning",
                    message: "正在读取",
                })
                this.loading = true
                const fileName = file.name;
                const fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
                if (!file.raw) {
                    ElMessage({
                        type: "warning",
                        message: "请上传附件！",
                    })
                    this.loading = false
                    return
                }
                if (fileType !== "xlsx" && fileType !== "xls") {
                    ElMessage({
                        type: "warning",
                        message: "附件格式错误，仅支持xlsx，请重新上传！",
                    })
                    this.loading = false
                    return
                }
                this.findHd(fileName).then(vs => {
                    axios({
                        method: "post",
                        url: 'getheader',
                        data: {
                            tabname: vs
                        },
                    }).then(res => {
                        if (this.data.tabname != vs) {
                            //导入新表清空表数据
                            this.data.tabname = vs
                            this.disabled = res.disabled
                            this.tableHead = res.tableHead
                            this.arrList = []
                            this.fileList = []
                            this.errorMessage = []
                        }
                        importExcel(file.raw, res, this.data.tabname).then(v => {
                            this.fileList = this.fileList.concat(v.correct)
                            this.arrList = this.arrList.concat(v.error)
                            this.errorMessage = this.errorMessage.concat(v
                                .errorMessage)
                            this.loading = false
                            if (v.error.length > 0) { //如果错误对象长度大于0 弹出错误修改窗
                                this.dialogTitle = '您有数据需要修改'
                                this.warning = true
                            }
                            if (!this.disabled) {
                                this.has = false
                            }
                        })
                    })
                })
            },
            ExporttAPI(e) { //导出表
                //清除编辑
                jsonToExcel(this.fileList, this.tableHead, this.data.tabname)
            },
            findHd(e) {
                //寻找文件名
                const _this = this
                let promise = new Promise(function(resolve, reject) {
                    let unm = 0
                    _this.tabsName.forEach((value) => {
                        if (e.indexOf(value) != -1) {
                            resolve(value);
                        }
                        if (e.indexOf(value) === -1) {
                            unm++
                        }
                        if (unm === _this.tabsName.length) {
                            ElMessage({
                                type: "warning",
                                message: "导入文件名不合法！",
                            })
                            _this.loading = false
                            return
                        }
                    })
                })
                return promise;
            }
        }
    }
</script>

<style scoped>
    .box-card-t {
        margin-top: 16px;
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>